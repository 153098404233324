@font-face {
  font-family: 'RCI Regular';
  src: local('RCI Regular'), url(../fonts/RCI-Regular.otf) format('truetype');
}

@font-face {
  font-family: 'RCI Bold';
  src: local('RCI Bold'), url(../fonts/RCI-Bold.otf) format('truetype');
}

@font-face {
  font-family: 'RCI Black';
  src: local('RCI Black'), url(../fonts/RCI-Black.otf) format('truetype');
}

@font-face {
  font-family: 'RCI Bk';
  src: local('RCI Bk'), url(../fonts/RCI-Bk.woff) format('woff');
}

@font-face {
  font-family: 'RCI Book';
  src: local('RCI Book'), url(../fonts/RCI-Book.otf) format('truetype');
}

body {
  margin: 0;
  font-family: 'RCI Book', -apple-system, BlinkMacSystemFont, 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
    'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #f9f9f9;
  font-size: 14px;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.slick-prev {
  left: 4px !important;
  width: 30px !important;
  height: 30px !important;
  z-index: 1;
}

.slick-next {
  right: 4px !important;
  width: 30px !important;
  height: 30px !important;
  z-index: 1;
}

.slick-prev:before,
.slick-next:before {
  font-size: 30px !important;
}

/* === Google Recaptcha === */
.grecaptcha-badge {
  visibility: hidden;
}
