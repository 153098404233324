.label {
  padding: 6px 12px;
  display: flex;
}

.col {
  display: flex;
  flex-direction: column;
}

.fluid-img {
  max-width: 100%;
  max-height: 350px;
  border-radius: 4px;
  box-sizing: border-box;
  margin: auto;
}

.main-img {
  width: 100%;
  object-fit: contain;
  box-sizing: border-box;
  cursor: pointer;
  display: flex;
  align-items: flex-start;
}

.modal-container {
  width: 100%;
  height: 90vh;
  display: flex !important;
  align-items: center;
  justify-content: center;
}

.modal-photo {
  border-radius: 4px;
  max-height: 100%;
  max-width: 100%;
  cursor: grab;
}

@media (max-width: 1400px) {
  .modal-container {
    height: 80vh;
  }
}
@media (max-width: 1200px) {
  .modal-container {
    height: 70vh;
  }
}

@media (max-width: 800px) {
  .modal-container {
    height: 60vh;
  }
}

.container-img {
  width: 100%;
  box-sizing: border-box;
  display: flex;
  position: relative;
}

.photo-container {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.check-block {
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: center;
  justify-content: space-between;
}

.small-slider {
  width: 100px !important;
  cursor: pointer;
}

.dot {
  width: 10px;
  height: 10px;
  top: -7px;
  left: -7px;
  position: absolute;
  border-radius: 50%;
  display: inline-block;
  background-color: black;
}

.dot-label {
  position: absolute;
  left: -5px;
  top: 2px;
  width: max-content;
  font-weight: bold;
  font-size: 16px;
}

.section-title {
  position: absolute;
  color: white;
  top: -12px;
  left: 12px;
  z-index: 1;
  padding: 2px 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  background-color: black;
}

.main {
  width: 100%;
  margin-left: auto;
  box-sizing: border-box;
  margin-right: auto;
  display: block;
  padding: 24px;
  max-width: 1400px;
}

.link {
  text-decoration: none;
  width: 100%;
  display: flex;
  justify-content: flex-end;
}

.fixed {
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  background-color: rgba(0, 0, 0, 0.2);
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  min-height: 100vh;
  z-index: 5000;
}
